import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import './css/FAQ.css';
import logo from './img/logo.png';

const FAQPage = () => {
  const history = useHistory();

  const redirectToLogin = () => {
    history.push('/login');
  };

  return (
    <div className="faq-page">
      <header className="landing-header">
        <img src={logo} alt="Company Logo" className="logo" />
        <div className="header-right">
          <nav>
            <ul>
              <li><Link to="/">Home</Link></li>
              <li><Link to="/FAQ">FAQ</Link></li>
              <li><Link to="/store">Store</Link></li>
              <li><Link to="/contact">Contact</Link></li>
            </ul>
          </nav>
          <button onClick={redirectToLogin} className="login-button-horizontal">Login</button>
        </div>
      </header>

      <div className="faq-container">
        <h1>Frequently Asked Questions</h1>
        <h2>How do I create an account?</h2>
        <p><Link to="/contact" style={{ color: 'white' }}>Contact us</Link> and we will create one for you!</p>
        <h2>What do I need to get started?</h2>
        <p>Each lock comes with a wall mounting shackle that needs to be bolted down and plugged into an outlet. After that, all you need is a Wi-Fi connection, and you're good to go!</p>
        <h2>What happens when someone doesn't return our equipment?</h2>
        <p>You can set an upfront deposit amount for each rental. If a customer doesn't return your equipment, they will be charged the full amount covering your losses.</p>
      </div>

      <footer className="landing-footer">
        <p>© 2024 Your Company. All rights reserved.</p>
        <nav>
          <ul>
            <li><a href="/terms">Terms of Service</a></li>
            <li><a href="/privacy">Privacy Policy</a></li>
          </ul>
        </nav>
      </footer>
    </div>
  );
};

export default FAQPage;
